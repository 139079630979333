"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require("../../common/tool");

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var cookieSWindowMain = /** @class */function () {
    function cookieSWindowMain() {
        this.getCookieDom = document.getElementById("kingshiper-get-cookies");
        this.init();
    }
    cookieSWindowMain.prototype.init = function () {
        this.gotCookieHandle();
    };
    // 允许获取cookie，点击按钮Got it
    cookieSWindowMain.prototype.gotCookieHandle = function () {
        var _this = this;
        var gotItBtn = document.querySelector(".got-it-btn");
        // 查看是否存在允许获取cookie
        var cookieValue = _tool2.default.getCookie("cookie-allow");
        if (cookieValue == "1") this.getCookieDom.style.display = "none";
        gotItBtn.addEventListener("click", function () {
            _this.getCookieDom.style.display = "none";
            var expirationDate = new Date();
            expirationDate.setDate(expirationDate.getFullYear() + 1);
            document.cookie = "cookie-allow=1; expires=".concat(expirationDate.toUTCString());
        });
        // js渲染cookie
        var cookieContent = _tool2.default.$_select(this.getCookieDom, ".text-p");
        cookieContent.innerHTML = "We use cookies to ensure that you get the best experience on our website. Click <a href=\"/cookie\" target=\"_blank\">here</a> to learn more.";
    };
    return cookieSWindowMain;
}();
exports.default = cookieSWindowMain;